import { ColumnItem } from '@components/columnLayout/utils';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { DealerRowDto, UserType } from '@generatedTypes/data-contracts';
import { DropdownOption } from '@hooks/useForm/useFormTypes';
import { useSelector } from 'react-redux';
import { selectUserType } from '@redux/reducers/slices/user';
import { FormSection } from '@components/forms/MuiFormSection';
import { InputWrapper } from '@components/controls/react-hook-form-friendly/smart';
import { useFormContext } from 'react-hook-form';
import { z } from 'zod';
import { Select } from '@components/controls/react-hook-form-friendly/smart/MuiSelect';
import { requiredString } from '@variables/zod';

export const getDealerDropdownValues = (dealerRows: DealerRowDto[] | undefined) => {
  return (
    dealerRows?.map(
      ({ id, name }) =>
        ({
          value: id,
          label: name,
        }) as DropdownOption<number>,
    ) ?? []
  );
};

export const clientInformationZodObject = {
  dealerId: z.coerce.number(),
  accountId: requiredString(),
};

const clientInformationZodSchema = z.object(clientInformationZodObject);

type ClientInformationValues = z.infer<typeof clientInformationZodSchema>;

type EditClientInformationFormProps = {
  disableForm: boolean;
  dealersDropdownValues: DropdownOption<number>[];
};

export const EditClientInformationForm: ColumnItem<EditClientInformationFormProps> = ({
  disableForm,
  dealersDropdownValues,
}) => {
  const {
    translate,
    translations: {
      users: { details },
    },
  } = useTranslations();

  const { control } = useFormContext<ClientInformationValues>();

  const userType = useSelector(selectUserType);

  return (
    <>
      <FormSection title={translate(details.clientInformation.header)}>
        <InputWrapper
          control={control}
          name="accountId"
          label={translate(details.clientInformation.clientNumberHeader)}
          type="text"
          isDisabled={userType !== UserType.Operation || disableForm}
          isRequired
        />
        <Select
          label={translate(details.clientInformation.associatedDealerHeader)}
          name="dealerId"
          options={dealersDropdownValues}
          disabled={userType !== UserType.Operation || disableForm}
          control={control}
        />
      </FormSection>
    </>
  );
};
